<template>
  <v-container fluid>
    <v-dialog v-model="dialog" width="400">
      <v-card aut-settings-dialog>
        <DialogTitle @close="$emit('close')" title="Add Custom Settings" />
        <v-card-text>
          <v-text-field
            outlined
            dense
            :validate-on-blur="true"
            aut-id
            label="Id"
            :rules="idRules"
            v-model="newSettings.id"
            :hint="idHint"
            persistent-hint
          ></v-text-field>
          <v-text-field
            outlined
            dense
            :validate-on-blur="true"
            label="Label"
            aut-label
            hint="alphanumeric"
            persistent-hint
            v-model="newSettings.label"
          ></v-text-field>
          <v-autocomplete
            outlined
            dense
            aut-value-type
            hide-selected
            clearable
            item-text="label"
            item-value="id"
            :items="valueTypes"
            hint="Choose a type of value"
            persistent-hint
            @change="handleValueTypeChange"
            v-model="newSettings.valueType"
            label="Value Type"
          >
          </v-autocomplete>
          <v-text-field
            outlined
            dense
            :validate-on-blur="true"
            v-if="newSettings.valueType == 'text'"
            label="Value"
            aut-settings-value
            hint="Default to use when not set by user"
            persistent-hint
            v-model="newSettings.value"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            :validate-on-blur="true"
            v-if="newSettings.valueType == 'number'"
            label="Value"
            aut-settings-value
            type="number"
            hint="Default to use when not set by user"
            persistent-hint
            v-model="newSettings.value"
          ></v-text-field>
          <v-autocomplete
            outlined
            dense
            v-if="newSettings.valueType == 'boolean'"
            aut-settings-value
            hide-selected
            clearable
            :items="[true, false]"
            hint="Default to use when not set by user"
            persistent-hint
            v-model="newSettings.value"
            label="Value"
          ></v-autocomplete>
          <v-text-field
            outlined
            dense
            :validate-on-blur="true"
            :disabled="
              newSettings.valueType == 'boolean' ||
              newSettings.valueType == 'number'
            "
            label="Options"
            hint="comma separated text. If provided, the options will be displayed as a dropdown"
            persistent-hint
            aut-settings-options
            v-model="newSettings.options"
          ></v-text-field>
          <v-autocomplete
            outlined
            dense
            :disabled="newSettings.valueType == 'boolean' || options.length > 1"
            aut-value-control
            hide-selected
            clearable
            item-text="label"
            item-value="id"
            :items="allowedControlTypes"
            hint="Choose a type of control"
            persistent-hint
            v-model="newSettings.control"
            label="Control Type"
          >
          </v-autocomplete>
          <v-text-field
            outlined
            dense
            aut-value-hint
            :validate-on-blur="true"
            label="Hint"
            hint="comma separated text. If more than one is provided, each will be displayed next to an option"
            persistent-hint
            v-model="newSettings.hint"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" @click="dialog = false" aut-cancel>
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            aut-add-settings
            @click="addSettings"
            class="ml-2"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-text>
        <FeatureSwitches
          :features="contentFeatures"
          @update="updateSettingsValue"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn aut-show-dialog @click="dialog = true">Add</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import { dialogMixin } from "@/components/mixin.js";
import { get, set, omit } from "lodash";
import { clone } from "@/util";
import { mapActions, mapState } from "vuex";
import FeatureSwitches from "@/views/administration/FeatureFlags/FeatureSwitches/FeatureSwitches.vue";

const debug = require('debug')('atman.settings.custom')  // eslint-disable-line

const valueTypes = [
  {
    id: "text",
    label: "Textual",
  },
  {
    id: "boolean",
    label: "Boolean",
  },
  {
    id: "number",
    label: "Number",
  },
];

const controlTypes = [
  {
    id: "switch",
    label: "Switch",
  },
  {
    id: "select",
    label: "Select",
  },
  {
    id: "text",
    label: "Input Text",
  },
  {
    id: "textarea",
    label: "Text Area",
  },
];
const idHint = "Allowed: lowercase alphanumeric characters and underscore";
export default {
  name: "SettingsCustom",
  mixins: [dialogMixin],
  props: {
    definition: {
      type: Object,
    },
  },
  data() {
    return {
      idHint,
      dialog: false,
      valueTypes,
      controlTypes,
      contentFeatures: {},
      idRules: [
        (val) =>
          /^[A-Za-z0-9_]{5,}$/.test(val) ||
          `Please enter a valid ID (${idHint})`,
      ],
      newSettings: {
        id: "",
        label: "",
        value: null,
        valueType: null,
        options: null,
        hint: null,
        control: null,
      },
    };
  },
  components: {
    FeatureSwitches,
  },
  computed: {
    ...mapState(["skin"]),
    options() {
      return (this.newSettings.options || "")
        .split(",")
        .map((item) => item.trim())
        .filter((item) => !!item);
    },
    allowedControlTypes() {
      let allowedTypes = [];
      switch (this.newSettings.valueType) {
        case "boolean": {
          allowedTypes = controlTypes.filter(({ id }) => id == "switch");
          break;
        }
        default: {
          allowedTypes = controlTypes.filter(({ id }) => id != "switch");
          break;
        }
      }
      if (this.options.length > 1) {
        allowedTypes = controlTypes.filter(({ id }) => id == "select");
      } else {
        allowedTypes = controlTypes.filter(({ id }) => id != "select");
      }
      return allowedTypes;
    },
  },
  mounted() {
    this.getCustomSettings();
  },
  methods: {
    ...mapActions(["saveSettingsToDomain"]),
    handleValueTypeChange() {
      switch (this.newSettings.valueType) {
        case "boolean": {
          this.newSettings.control = "switch";
          break;
        }
      }
    },
    addSettings() {
      debug(`in addSettings`, this.newSettings);
      const settings = clone(this.newSettings);
      this.dialog = false;
      if (settings.options) {
        settings.options = (settings.options || "")
          .split(",")
          .map((item) => item.trim())
          .filter((item) => !!item);
      }
      if (settings.hint) {
        const hints = (settings.hint || "")
          .split(",")
          .map((item) => item.trim())
          .filter((item) => !!item);
        if (hints.length > 1) {
          settings.hint = hints;
        }
      }
      this.updateSettings({
        name: settings.id,
        value: clone(omit(settings, "id")),
      });
    },
    getCustomSettings() {
      let customSettings = this.skin.custom_settings || {};
      this.contentFeatures = customSettings;
      debug(`contentFeatures`, this.contentFeatures);
    },
    updateSettingsValue({ name, value }) {
      let customSettingDefinition = get(this.skin.custom_settings || {}, name);
      if (!customSettingDefinition) {
        console.error(`settings ${name} not found`);
        return;
      }
      customSettingDefinition.value = value;
      this.updateSettings({ name, value: customSettingDefinition });
    },
    updateSettings({ name, value }) {
      const settings = {};
      set(settings, `custom_settings.${name}`, value);
      this.saveSettingsToDomain(settings);
    },
  },
};
</script>
